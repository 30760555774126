import React from "react";
import styled from "styled-components";

import Markdown from "../Application/Markdown";
import { useRecoilValue } from "recoil";
import { globalDataState } from "../../States";

const Wrapper = styled.div`
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 120px;
  padding-bottom: 50px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
  }

  h1 {
    padding-bottom: 25px;
  }
`;

const Item = styled.div`
  padding-bottom: 50px;

  h2 {
    font-family: "NEXTBook-Light";
    font-size: 24px;
    letter-spacing: 0;
    line-height: 28px;
    color: #e20015;
    margin-bottom: 5px;
  }

  p {
    font-family: "NEXTBook-Regular";
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }

  a {
    color: #e20015;
  }

  a:hover {
    color: #262626;
  }
`;

const FAQ = (props) => {
  const data = useRecoilValue(globalDataState);

  return (
    <Wrapper>
      <h1>FAQ</h1>

      {data.FAQ !== null || data.FAQ !== undefined ? (
        <>
          {data.FAQ.map((value, index) => (
            <Item key={index}>
              <h2>{value.Title}</h2>
              <Markdown data={value.Copy} />
            </Item>
          ))}
        </>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};

export default FAQ;
