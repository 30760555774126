import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";

export const productIdState = atom({
  key: "productId",
  default: "",
});

export const videoIdState = atom({
  key: "videoId",
  default: "",
});

export const previewIdState = atom({
  key: "previewId",
  default: "",
});

export const checkoutIdState = atom({
  key: "checkoutId",
  default: "",
});

export const checkoutIdLiveState = atom({
  key: "checkoutIdLive",
  default: "",
});

export const titleState = atom({
  key: "title",
  default: "",
});

export const copyState = atom({
  key: "copy",
  default: "",
});

export const sublineState = atom({
  key: "subline",
  default: "",
});

export const globalDataState = atom({
  key: "globalDataState",
  default: [],
});

export const priceState = atom({ key: "price", default: "" });

export const showVideoState = atom({ key: "video", default: false });

export const pushToViewState = atom({ key: "pushToView", default: false });
export const pushToIdState = atom({ key: "pushToId", default: "" });
