import React from "react";
import { RecoilRoot } from "recoil";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { setConfiguration } from "react-grid-system";
import "./App.css";

import Homepage from "./components/Homepage";
import Cookies from "./components/Cookies";

setConfiguration({
  defaultScreenClass: "xl",
  gridColumns: 0,
  breakpoints: [576, 768, 992, 1200],
  containerWidths: ["100%"], // 1140
  gutterWidth: 0,
  gridColumns: 12,
});

class App extends React.Component {
  state = {
    isDev: "true",
  };

  render() {
    return (
      <div>
        <h1>DBV Videoportal</h1>
        <p>Dieser Service wird aktuell nicht mehr angeboten.</p>
        <a href="https://www.betonverein.de/">Zum DBV</a>
      </div>
    );
  }
}

export default App;
/*
         <Router>
        <RecoilRoot>
          <Homepage />
          <Cookies />
        </RecoilRoot>
      </Router>
        */
