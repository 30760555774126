import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { withRouter } from "react-router-dom";
import Menu from "../Menu";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 160px;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const Logo = styled.div`
  position: absolute;
  left: 130px;
  top: 30px;

  width: 105px;
  height: 80px;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 900px) {
    left: 25px;
  }
`;

const Content = styled.div`
  position: relative;
  max-width: 1920px;

  margin: 0 auto;
  padding-left: 90px;
  padding-right: 100px;

  @media (max-width: 900px) {
    padding-right: 25px;
  }

  display: flex;
  justify-content: space-between;

  ul {
    bottom: 0px;
    padding: 0px;
    padding-top: 54px;
    display: block;

    li {
      display: inline-block;
      cursor: pointer;
      padding: 0;
      padding-right: 32px;

      font-family: "NEXTBook-Regular";
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
  }
`;

const Navi = styled.div``;

class Header extends React.Component {
  buttonClick = (id) => {};

  render() {
    return (
      <Wrapper>
        <Content>
          <Link to="/">
            <Logo>
              <img src="/dbv-logo.svg"></img>
            </Logo>
          </Link>
          <Navi>
            <ul>
              <li onClick={this.buttonClick.bind(this, "dbv")}>
                <a href="https://www.betonverein.de/" target="_blank">
                  Zur DBV Homepage
                </a>
              </li>
              {/*
 <li onClick={this.buttonClick.bind(this, "warenkorb")}>
                Warenkorb
              </li>
                */}
            </ul>
          </Navi>
        </Content>
      </Wrapper>
    );
  }
}

export default withRouter(Header);
