import React from "react";
import styled from "styled-components";
import CookieConsent from "react-cookie-consent";

const Wrapper = styled.div`
  p {
    padding-left: 10px;
  }

  button {
    font-family: "NEXTBook-Regular";
    font-weight: 300;
    font-size: 16px;
  }
`;

class Cookies extends React.Component {
  render() {
    return (
      <Wrapper>
        <CookieConsent
          buttonText="Ja, ich stimme zu!"
          cookieName="dbvwebseminarecookie"
          style={{ background: "#666" }}
          buttonStyle={{
            background: "#e20015",
            color: "#fff",
          }}
          expires={150}>
          <p>
            Laut DSGVO müssen wir Sie über die Verwendung von Cookies
            informieren. Durch Ihren Besuch stimmen Sie dem zu.
          </p>
        </CookieConsent>
      </Wrapper>
    );
  }
}

export default Cookies;
