import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import Helmet from "react-helmet";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  productIdState,
  videoIdState,
  previewIdState,
  checkoutIdState,
  checkoutIdLiveState,
  titleState,
  copyState,
  sublineState,
  priceState,
} from "../../States";

const Wrapper = styled.div`
  position: fixed;
  z-index: 20;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(255, 255, 255, 0.9);
`;

const ScrollWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
  overflow-y: scroll;
`;

const ContentWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
  max-width: 1280px;

  .XplayerWrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }

  .playerWrapper {
    position: relative;
    width: 100%;
    max-width: 1280px;
    height: auto;

    padding-bottom: 50px;
  }

  .previewWrapper {
    position: relative;
    width: 100%;
    max-width: 640px;
    height: auto;
  }

  .reactPlayer {
    position: relative;
    top: 0;
    left: 0;
  }
`;

const ButtonWrapper = styled.div``;

const BtnClose = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  width: 20px;
  height: 20px;

  &:before {
    position: absolute;
    content: "";
    top: 9px;
    left: -9px;
    width: 40px;
    height: 2px;
    transform: rotate(-45deg);
    background: #000;
  }
  &:after {
    position: absolute;
    content: "";
    top: 9px;
    left: -9px;
    width: 40px;
    height: 2px;
    transform: rotate(45deg);
    background: #000;
  }

  cursor: pointer;
`;

const VideoBox = styled.div`
  position: absolute;
  z-index: 9;
  left: 0px;
  right: 0px;
  height: auto;
`;

const ContentBox = styled.div`
  position: relative;
  left: 0px;
  right: 0px;
  height: auto;

  h1 {
    color: #e20015;
    font-size: 34px;
    letter-spacing: 0;
    line-height: 44px;
    padding-bottom: 10px;
  }

  h2 {
    color: #262626;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 26px;
    padding-bottom: 10px;
  }

  h3 {
    color: #a3a3a3;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 28px;
    padding-bottom: 10px;
  }

  @media (max-width: 900px) {
    h1 {
      font-size: 26px;
      line-height: 30px;
    }

    h2 {
      font-size: 16px;
      line-height: 22px;
    }

    h3 {
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

const Btn = styled.div`
  display: inline-block;
  color: #fff;
  line-height: 50px;
  padding-left: 35px;
  padding-right: 35px;
  height: 50px;
  border-radius: 3px;
  background-color: #e20015;

  font-family: "NEXTBook-Regular";
  font-size: 16px;
  font-weight: 300;
  margin-right: 25px;
  cursor: pointer;
  margin-top: 10px;
`;

/* *** */

let plenigoBase = null;

const CheckOutBox = (props) => {
  const isLive = props.isLive;

  const [productId, setProductId] = useRecoilState(productIdState);
  const [previewId, setPreviewId] = useRecoilState(previewIdState);
  const [checkoutId, setCheckoutId] = useRecoilState(checkoutIdState);
  const [checkoutIdLive, setCheckoutIdLive] = useRecoilState(
    checkoutIdLiveState
  );

  let finalCheckout = checkoutId;
  if (isLive === true) {
    finalCheckout = checkoutIdLive;
  }

  let _targetUrl = "https://videoportal.betonverein.de/" + productId;

  const plenigoCheckOut = () => {
    const checkoutConfig = {
      paymentData: finalCheckout,
      dataProductId: productId,
      targetUrl: _targetUrl,
      startWithRegistration: true,
      dataPaywallSourceElementId: "sourceId",
      dataPaywallTargetElementId: "targetId",
    };

    plenigoBase.checkout(checkoutConfig);
  };

  const plenigoLogin = () => {
    const loginConfig = {
      paymentData: finalCheckout,
      dataProductId: productId,
      targetUrl: _targetUrl,
      startWithRegistration: true,
      dataPaywallSourceElementId: "sourceId",
      dataPaywallTargetElementId: "targetId",
    };
    plenigoBase.login(loginConfig);
  };

  return (
    <ContentBox>
      <Col sm={12} md={12} lg={12} xl={12}>
        <div className="previewWrapper">
          <ReactPlayer
            className="reactPlayer"
            controls={true}
            playing={true}
            volume={0}
            muted={true}
            url={previewId}
            width="100%"
            height="auto"
          />
        </div>
      </Col>

      <Col sm={12} md={12} lg={12} xl={12}>
        <Btn onClick={(e) => plenigoCheckOut()}>
          Jetzt kostenpflichtig leihen
        </Btn>
        <Btn onClick={(e) => plenigoLogin()}>Nochmal ansehen</Btn>
      </Col>
    </ContentBox>
  );
};

let isInitialized = false;

const CheckoutView = (props) => {
  const [productId, setProductId] = useRecoilState(productIdState);
  const [videoId, setVideoId] = useRecoilState(videoIdState);
  const [checkoutId, setCheckoutId] = useRecoilState(checkoutIdState);
  const [title, setTitle] = useRecoilState(titleState);
  const [copy, setCopy] = useRecoilState(copyState);
  const [subline, setSubline] = useRecoilState(sublineState);
  const [price, setPrice] = useRecoilState(priceState);

  const isLive = props.isLive;

  let testMode = true;
  if (isLive === true) {
    testMode = false;
  }

  const history = useHistory();

  const streamId = videoId;
  const streamUrl =
    "https://playout.3qsdn.com/" +
    streamId +
    "?js=true&container=sdnPlayer_FzftKbGxvTJNhRD";

  useEffect(() => {
    setTimeout(() => {
      if (isInitialized === false) {
        plenigoBase = window.plenigo;
        isInitialized = true;
      }
    }, 500);

    const handler = (event) => {
      let data;
      try {
        data = JSON.parse(event.data);
        if (data.successRedirectUrl !== undefined) {
          history.push(productId);
          history.go(0);
        }
      } catch (ex) {
        return;
      }
    };

    window.addEventListener("message", handler);
    // clean up
    return () => window.removeEventListener("message", handler);
  }, [plenigoBase]);

  return (
    <Wrapper>
      <Helmet>
        <script
          type="application/javascript"
          src="https://static.plenigo.com/static_resources/javascript/zuqGP3DYJ3SgTIcSnSag/plenigo_sdk.min.js"
          data-client-paywall="true"
          data-paywall-type="show"
          data-lang="de"
          data-video="3q"
          data-test-mode={testMode}
          data-use-events="true"
          data-product-id={productId}
          data-paywall-source-element-id="sourceId"
          data-paywall-target-element-id="targetId"></script>
        <script
          type="text/javascript"
          src="https://playout.3qsdn.com/player/js/sdnplayer.js"></script>
        <script type="text/javascript" src={streamUrl}></script>
      </Helmet>
      <ScrollWrapper>
        <ContentWrapper>
          <ButtonWrapper>
            <Container>
              <Row align="center">
                {/* *** Title etc. ... *** */}
                <Col sm={12} md={12} lg={12} xl={12}>
                  <Col sm={12} md={12} lg={12} xl={12}>
                    <ContentBox>
                      <h1>{title}</h1>
                      <h2>{copy}</h2>
                      <h3>{subline}</h3>
                      <h2>{price}</h2>
                    </ContentBox>
                  </Col>

                  {/* *** Preview video ... *** */}
                  <Col sm={12} md={12} lg={12} xl={12}>
                    <div id="targetId">
                      <CheckOutBox
                        productId={productId}
                        checkoutId={checkoutId}
                        isLive={isLive}
                      />
                    </div>
                  </Col>
                </Col>

                {/* *** Full video ... *** */}
                <Col sm={12} md={12} lg={12} xl={12}>
                  <div id="sourceId" style={{ display: "none" }}>
                    <ContentBox>
                      <div className="playerWrapper">
                        <div id="sdnPlayer_FzftKbGxvTJNhRD"></div>
                      </div>
                    </ContentBox>
                  </div>
                </Col>
              </Row>
            </Container>
          </ButtonWrapper>
        </ContentWrapper>
      </ScrollWrapper>
      <BtnClose onClick={props.onCloseVideo} />
    </Wrapper>
  );
};

export default CheckoutView;
