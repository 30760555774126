import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { atom, selector, useRecoilValue, useRecoilState } from "recoil";
import { pushToViewState, pushToIdState } from "../../States";

const Item = styled.div`
  position: relative;

  height: 250px;
  margin: 0 auto;
  margin-bottom: 50px;

  width: 467px;

  @media (max-width: 900px) {
    width: 360px;
  }

  cursor: pointer;

  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;

  &:hover {
    transform: translate(0px, -5px);
    .cta {
    }
  }
`;

const Frame = styled.div`
  position: absolute;
  width: 100%;
  height: 220px;
  border: 2px solid #e9e9e9;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 170px;
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Title = styled.h1`
  color: #e20015;
  margin-bottom: 5px;
`;

const Copy = styled.p`
  color: #262626;
`;

const Subline = styled.p`
  color: #a3a3a3;
  padding-bottom: 20px;
`;

const CallToAction = styled.div`
  position: absolute;
  left: 0px;
  right: 20px;
  bottom: 0px;
  background-color: #e20015;
  padding: 20px;
  padding-right: 25px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  cursor: pointer;

  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;

  .icon {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 5px;
    left: -20px;
    transform: rotate(135deg);

    &:after {
      position: absolute;
      content: "";
      width: 8px;
      height: 2px;
      background: #fff;
      top: 0xp;
      left: 0px;
    }

    &:before {
      position: absolute;
      content: "";
      width: 2px;
      height: 8px;
      background: #fff;
      top: 0xp;
      left: 0px;
    }
  }
`;

const Price = styled.p`
  color: #fff;
  line-height: 20px;
`;

const Button = styled.div`
  position: relative;
  color: #fff;
  font-weight: 500;
  line-height: 20px;
`;

const Thumb = (props) => {
  const [pushView, setPushView] = useRecoilState(pushToViewState);

  const {
    title,
    copy,
    subline,
    price,
    productid,
    videoid,
    previewid,
    checkoutid,
    checkoutidlive,
    cta,
    pushId,
  } = props;

  const handleClick = () => {
    props.onClick(
      props.title,
      props.copy,
      props.subline,
      props.price,
      props.productid,
      props.videoid,
      props.previewid,
      props.checkoutid,
      props.checkoutidlive
    );
  };

  useEffect(() => {
    if (pushId === productid && pushView === true) {
      setPushView(false);
      setTimeout(() => {
        handleClick();
      }, 500);
    }
  }, []);

  return (
    <Item onClick={handleClick}>
      <Frame />

      <Wrapper>
        <Header>
          <div>
            <Title>{title}</Title>
            <Copy>{copy}</Copy>
          </div>
          <Subline>{subline}</Subline>
        </Header>

        <CallToAction className="cta">
          <Price>{price}</Price>
          <Button>
            <div className="icon" />
            <p>{cta}</p>
          </Button>
        </CallToAction>
      </Wrapper>
    </Item>
  );
};

export default Thumb;
