import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1920px;
  height: auto;
  padding-top: 40px;
  background-color: #262626;
`;

const Content = styled.div`
  position: relative;
  height: auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    font-family: "NEXTBook-Regular";
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 35px;
    padding-bottom: 10px;
    text-transform: uppercase;
  }

  a,
  p {
    font-size: 15px;
    letter-spacing: 0.5px;
  }

  a {
    color: #fff;
  }
`;

const Top = styled.div`
  align-self: stretch;
  padding-bottom: 40px;
  padding-left: 130px;

  @media (max-width: 900px) {
    padding-left: 25px;
  }
`;

const Bottom = styled.div`
  background: #3c3c3c;
  padding-left: 130px;
  @media (max-width: 900px) {
    padding-left: 25px;
  }
`;

const LinkBox = styled.div`
  ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
    li {
      cursor: pointer;
      padding: 0;
      color: #5a5a5a;
      line-height: 35px;
      a {
        &.active {
          color: #e20015;
        }
      }
    }
  }
  margin-bottom: 10px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding-bottom: 30px;
  }
`;

const SocialBox = styled.div`
  ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
    li {
      cursor: pointer;
      padding: 0;
      color: #5a5a5a;
      line-height: 35px;
      a {
        &.active {
          color: #e20015;
        }
      }
    }
  }
  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding-bottom: 30px;
  }
`;

const Break = styled.div`
  width: 10px;
  height: 2px;
`;

const Kontakt = styled.div`
  span {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding-bottom: 30px;
  }
`;

const Copyright = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  @media (max-width: 900px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const BottomInfo = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;

  span {
    padding-left: 10px;
    padding-right: 10px;
  }
  display: flex;
  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding-top: 10px;
    padding-bottom: 10px;

    flex-direction: column;
  }
`;

const Icon = styled.div`
  display: flex;
  width: 24px;
  height: 35px;

  p {
    padding-left: 10px;
    line-height: 30px;
  }
  img {
    width: 24px;
    height: 24px;
  }
`;

class Footer extends React.Component {
  render() {
    return (
      <Wrapper>
        <Content>
          <Top>
            <Container fluid>
              <Row>
                <Col sm={12} md={12} lg={6} xl={3}>
                  <LinkBox>
                    <h1>Schnelleinstieg</h1>
                    <a href="https://www.betonverein.de/" target="_blank">
                      DBV Homepage
                    </a>
                  </LinkBox>
                </Col>

                <Col sm={12} md={12} lg={6} xl={3}>
                  <SocialBox>
                    <h1>Folgen Sie uns auf</h1>
                    <ul>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/betonverein/"
                          target="_blank">
                          <Icon>
                            <img src="/icon-linkedin.svg"></img>
                            <p>LinkedIn</p>
                          </Icon>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.xing.com/companies/deutscherbeton-undbautechnik-vereine.v."
                          target="_blank">
                          <Icon>
                            <img src="/icon-xing.svg"></img>
                            <p>Xing</p>
                          </Icon>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/betonverein"
                          target="_blank">
                          <Icon>
                            <img src="/icon-facebook.svg"></img>
                            <p>Facebook</p>
                          </Icon>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/betonverein"
                          target="_blank">
                          <Icon>
                            <img src="/icon-twitter.svg"></img>
                            <p>Twitter</p>
                          </Icon>
                        </a>
                      </li>
                    </ul>
                  </SocialBox>
                </Col>

                <Col sm={12} md={12} lg={6} xl={4}>
                  <Kontakt>
                    <h1>Kontakt</h1>
                    <p>
                      Deutscher Beton- und Bautechnik-Verein E.V.
                      <br />
                      Kurfürstenstraße 129 <br />
                      10785 Berlin <br />
                      <br />
                      Telefon: 030 236096-0 <br />
                      Telefax: 030 236096-23 <br />
                      <a href="mailto:info@betonverein.de">
                        info@betonverein.de
                      </a>
                    </p>
                  </Kontakt>
                </Col>
              </Row>
            </Container>
          </Top>

          <Bottom>
            <Container fluid>
              <Row>
                <Col sm={12} md={12} lg={6} xl={6}>
                  <Copyright>
                    <p>
                      Copyright 2022: Deutscher Beton- und Bautechnik-Verein
                      E.V.
                    </p>
                  </Copyright>
                </Col>

                <Col sm={12} md={12} lg={4} xl={4}>
                  <BottomInfo>
                    <a
                      href="https://www.betonverein.de/impressum"
                      target="_blank">
                      Impressum
                    </a>
                    <Break></Break>
                    <a
                      href="https://www.betonverein.de/datenschutzerklaerung"
                      target="_blank">
                      Datenschutz
                    </a>
                    <Break></Break>
                    <a
                      href="https://www.betonverein.de/uploads/DBV_Videoportal_Allgemeine_Geschaeftsbedingungen_ac27a735df.pdf"
                      target="_blank">
                      AGB
                    </a>
                  </BottomInfo>
                </Col>
              </Row>
            </Container>
          </Bottom>
        </Content>
      </Wrapper>
    );
  }
}

export default Footer;
