import React from "react";
import ReactMarkdown from "react-markdown";

const url = "https://dbv.ext-tech-team.de/";

const ImageOrLink = (props) => {
  if (props.href.match(/\.(jpe?g|png|gif)$/)) {
    return <img src={props.href} />;
  }

  const check = props.href.substring(0, 8);

  if (check === "/uploads") {
    return (
      <a href={url + props.href} target="_blank">
        {props.children}
      </a>
    );
  } else {
    return (
      <a href={props.href} target="_blank">
        {props.children}
      </a>
    );
  }
};

const Markdown = ({ data }) => {
  return (
    <ReactMarkdown
      source={data}
      renderers={{ link: ImageOrLink }}
      transformImageUri={(uri) =>
        uri.startsWith("http") ? uri : `${url}${uri}`
      }
    />
  );
};

export default Markdown;
