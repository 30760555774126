import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import "./fonts/NEXTBook-Regular.css";
import "./fonts/NEXTBook-Light.css";
import "./fonts/NEXTBook-Bold.css";

import "./index.css";

import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
