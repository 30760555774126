import React, { useState, useEffect } from "react";

import Helmet from "react-helmet";
import API from "../../api";
import { atom, selector, useRecoilValue, useRecoilState } from "recoil";
import FAQ from "../FAQ";
import Markdown from "../Application/Markdown";
import {
  productIdState,
  videoIdState,
  previewIdState,
  checkoutIdState,
  checkoutIdLiveState,
  titleState,
  copyState,
  sublineState,
  priceState,
  globalDataState,
  pushToViewState,
  pushToIdState,
} from "../../States";

import styled, { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";

import Header from "../Header";
import Hero from "../Hero";
import Footer from "../Footer";
import Thumb from "../Thumb";
import CheckoutView from "../CheckoutView";

const Wrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
`;

const Base = styled.div`
  max-width: 1020px;
  margin: 0 auto;
  margin-top: 100px;
`;

const Infos = styled.div`
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

  p,
  h2 {
    color: #262626;
    font-family: "NEXTBook-Light";
  }

  h2 {
    padding-bottom: 10px;
  }
`;

const Intro = styled.div`
  p,
  a {
    font-family: "NEXTBook-Light";
    color: #e20015;
    font-size: 34px;
    font-weight: 300;
    letter-spacing: 0.29px;
    line-height: 50px;
  }

  a {
    color: #e20015;
  }

  a:hover {
    color: #262626;
  }

  padding-bottom: 120px;
  padding-top: 100px;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 900px) {
    p,
    a {
      letter-spacing: 0.19px;
      font-size: 26px;
      line-height: 34px;
    }
  }
`;

const Group = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
`;

const GroupTitle = styled.div`
  height: 75px;
  margin-bottom: 80px;

  :after {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    content: "";
    height: 75px;
    border-top: 3px solid #e9e9e9;
    border-bottom: 3px solid #e9e9e9;
  }

  h1 {
    padding-top: 5px;
    color: #a3a3a3;
    font-size: 18px;
    line-height: 75px;
    text-align: center;
  }
`;

const Theme = {
  mobilesize: "992px",
};

/* *** */

let plenigoBase = null;

/* *** */

const VideoPage = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [globalData, setGlobalData] = useRecoilState(globalDataState);
  //
  const [pushId, setPushId] = useRecoilState(pushToIdState);
  //
  const [showCheckOut, setShowCheckOut] = useState(false);
  //
  const [productId, setProductId] = useRecoilState(productIdState);
  const [videoId, setVideoId] = useRecoilState(videoIdState);
  const [previewId, setPreviewId] = useRecoilState(previewIdState);
  const [checkoutId, setCheckoutId] = useRecoilState(checkoutIdState);
  const [checkoutIdLive, setCheckoutIdLive] = useRecoilState(
    checkoutIdLiveState
  );
  const [title, setTitle] = useRecoilState(titleState);
  const [copy, setCopy] = useRecoilState(copyState);
  const [subline, setSubline] = useRecoilState(sublineState);
  const [price, setPrice] = useRecoilState(priceState);
  //

  const handleClick = (
    title,
    copy,
    subline,
    price,
    productid,
    videoid,
    previewid,
    checkoutid,
    checkoutidlive
  ) => {
    setTitle(title);
    setCopy(copy);
    setSubline(subline);
    setPrice(price);
    setProductId(productid);
    setVideoId(videoid);
    setPreviewId(previewid);
    setCheckoutId(checkoutid);
    setCheckoutIdLive(checkoutidlive);

    let timeoutId = setTimeout(() => {
      document.body.classList.add("noscroll");
      setShowCheckOut(true);
    }, 300);
  };

  const closeLayer = () => {
    document.body.classList.remove("noscroll");
    setShowCheckOut(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const call = await API.get("/videoportal");
        const data = await call.data;

        setGlobalData(data);
        setIsLoading(false);
      } catch (err) {
        console.log("Error fetching data: ", err);
      }
    };
    fetchData();
  }, []);

  const ctaTitle = globalData.CTA;
  const isLive = globalData.Livemodus;

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <>
          <Intro>
            <Markdown data={globalData.Copy} />
          </Intro>
          <Infos>
            <h2>{globalData.InfoHeadline}</h2>
            <Markdown data={globalData.InfoCopy} />
          </Infos>
          <Container fluid>
            <Group>
              {globalData.VideoGroup !== null &&
                globalData.VideoGroup.map((value) => (
                  <Row key={value.id} align="center">
                    <Col sm={12} md={12} lg={12} xl={12}>
                      <GroupTitle>
                        <h1>{value.Category}</h1>
                      </GroupTitle>
                    </Col>

                    {value.Videos.map((subvalue) => (
                      <Col key={subvalue.id} sm={12} md={12} lg={6} xl={6}>
                        <Thumb
                          title={subvalue.Video.Title}
                          copy={subvalue.Video.Copy}
                          subline={subvalue.Video.Subline}
                          price={subvalue.Video.Price}
                          productid={subvalue.Video.ProductId}
                          videoid={subvalue.Video.VideoId}
                          previewid={subvalue.Video.PreviewId}
                          checkoutid={subvalue.Video.CheckoutId}
                          checkoutidlive={subvalue.Video.CheckoutIdLive}
                          cta={ctaTitle}
                          pushId={pushId}
                          onClick={handleClick}
                        />
                      </Col>
                    ))}
                  </Row>
                ))}
            </Group>
          </Container>
          <FAQ />
          {showCheckOut === true ? (
            <CheckoutView
              productId={productId}
              videoId={videoId}
              checkoutId={checkoutId}
              checkoutIdLive={checkoutIdLive}
              isLive={isLive}
              onCloseVideo={() => closeLayer()}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

/* **** */

let isInitialized = false;

const Homepage = (props) => {
  const renderCheckout = true;

  const pathname = window.location.pathname;

  const [pushView, setPushView] = useRecoilState(pushToViewState);
  const [pushId, setPushId] = useRecoilState(pushToIdState);
  const pathArray = window.location.pathname.split("/");

  useEffect(() => {
    setTimeout(() => {
      if (isInitialized === false) {
        plenigoBase = window.plenigo;
        plenigoBase.start();
        isInitialized = true;
      }
    }, 1000);

    if (pathArray.length === 2) {
      setPushView(true);
      setPushId(pathArray[1]);
    }
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <Wrapper>
        <Helmet>
          <script
            type="text/javascript"
            src="https://player.3qsdn.com/js3q.latest.js"></script>
          <script
            type="application/javascript"
            src="https://static.plenigo.com/static_resources/javascript/zuqGP3DYJ3SgTIcSnSag/plenigo_sdk.min.js"></script>
        </Helmet>
        <Header />

        <Switch>
          <Route path="/">
            <Hero />
            <Base>
              <VideoPage />
            </Base>
          </Route>
        </Switch>

        <Footer />
      </Wrapper>
    </ThemeProvider>
  );
};

export default withRouter(Homepage);
