import React from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Wrapper = styled.div`
  position: relative;
  height: 380px;
`;

const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 380px;

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
`;

const Image = styled.div.attrs((props) => ({
  src: props.source || "",
}))`
  height: 100%;

  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

const Overlay = styled.div`
  position: absolute;
  top: 258px;
  left: 230px;
  height: 150px;
  width: 672px;
  background-color: rgba(226, 0, 21, 0.9);

  @media (max-width: 900px) {
    left: 0px;
    width: 100%;
  }
`;

const Title = styled.h1`
  position: absolute;
  top: 36px;
  left: 40px;
  color: #fff;
  font-family: "NEXTBook-Light";
  font-size: 48px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 56px;

  @media (max-width: 900px) {
    left: 25px;
    font-size: 38px;
  }
`;

class Hero extends React.Component {
  render() {
    const renderImage = "./hero.jpg";

    return (
      <Wrapper>
        <ImageWrapper>
          <Image source={renderImage}></Image>
        </ImageWrapper>
        <Overlay>
          <Title>DBV-Videoportal</Title>
        </Overlay>
      </Wrapper>
    );
  }
}

export default Hero;
